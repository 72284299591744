import React from 'react';
import Modal from 'cccisd-modal';
import PropTypes from 'prop-types';
import { RegisterLoginForm, ActivationForm } from 'cccisd-laravel-nexus';
import axios from 'cccisd-axios';
import style from './style.css';
import { Field, CccisdSelect } from 'cccisd-formik';
import Notification from 'cccisd-notification';
import isEmail from 'validator/lib/isEmail';
// import { client as apollo, gql } from 'cccisd-apollo';
const roles = [
    { value: null, label: '--Please Select a Role--' },
    { value: 'teacher', label: 'Teacher' },
    { value: 'coach', label: 'Coach' },
    { value: 'admin', label: 'Admin' },
];

const Boilerplate = window.cccisd.boilerplate;
const Fortress = window.cccisd.fortress;

export default class SignUp extends React.Component {
    static propTypes = {
        match: PropTypes.object,
        location: PropTypes.object,
    };

    modal = React.createRef();
    activationModal = React.createRef();

    state = {
        pawn: null,
        message: null,
        promote: false,
        header: 'System Message',
        alertType: 'alert alert-info',
        destination: Boilerplate.url('/'),
    };

    handleSubmit = async values => {
        // REGISTER FORM SUBMIT
        const response = await axios.post(Boilerplate.route('check.user.registration'), {
            first_name: values.first_name.trim(),
            last_name: values.last_name.trim(),
            email: values.email.trim(),
            username: values.email.trim(),
            role: values.role,
        });
        // Check if this is a new user
        if (response) {
            if (response.data.status === 'success') {
                this.processRegistrationData(values, response.data.data);
            }
        }
    };

    handleClick = event => {
        // MODAL BUTTON
        const { activate } = this.state;

        this.modal.current.close();
        if (activate) {
            window.location = this.state.destination;
        }
    };

    onSuccess = async () => {
        // LOGIN SUCCESS
        if (this.state.promote) {
            await this.promotePawn(this.state.pawn);
            this.setState({
                promote: false,
            });
            Notification({
                message: 'Promotion to coach successful!',
                type: 'success',

                duration: 3000,
            });
        } else if (this.state.makeCoachAdmin) {
            await this.addCoachRole(this.state.values);

            Notification({
                message: 'Promotion to admin successful!',
                type: 'success',

                duration: 3000,
            });
        } else if (this.state.makeAdmin) {
            await this.makeAdmin(this.state.pawn);
        }
        setTimeout(() => {}, 3000);
    };

    onClose = () => {
        // MODAL CLOSE
        if (this.state.promote && Fortress.auth()) {
            window.location = this.state.destination;
        }
    };

    addCoachRole = async values => {
        const data = {
            ...values,
            user: values.email,
        };

        await axios.post(Boilerplate.route('api.nexus.pawn.store'), data);
    };

    makeAdmin = async pawn => {
        await axios.post(Boilerplate.route('make.pawn.admin'), {
            pawnId: pawn.pawnId,
            pawnHash: pawn.pawnHash,
        });
    };

    promotePawn = async pawn => {
        await axios.post(Boilerplate.route('promote.pawn.role'), {
            pawnId: pawn.pawnId,
            pawnHash: pawn.pawnHash,
        });
    };

    demotePawn = async pawn => {
        await axios.post(Boilerplate.route('demote.pawn.role'), {
            pawnId: pawn.pawnId,
            pawnHash: pawn.pawnHash,
        });
    };

    processRegistrationData = async (values, data) => {
        if (data) {
            // NOT EXIST -> FLOW -> activation page / email
            if (data.message === 'User added') {
                this.setState({
                    pawn: { pawnId: data.user.id, pawnHash: data.user.random_hash },
                    destination: Boilerplate.url('/account/activate/' + data.user.user.activation_token),
                    message: `Thank you for registering with the Classroom Check-Up! Please click Continue to activate your account.`,
                    header: `Registration Success`,
                    alertType: 'alert alert-success',
                    activate: true,
                });
                this.modal.current.open();
            } else if (data.message === 'User exists') {
                const notRole = data.pawns.every(item => item.data_type !== values.role);
                const isAdmin = data.pawns.some(item => item.data_type === 'admin');
                const isCoach = data.pawns.some(item => item.data_type === 'coach');
                const isTeacher = data.pawns.some(item => item.data_type === 'teacher');
                if (data.user.activated_at) {
                    // EXISTS && ACTIVATED w/ DIFF ROLE - > Message -> Landing Page
                    if (data.pawns) {
                        let message;
                        if (notRole && values.role === 'teacher') {
                            if (isAdmin) {
                                message =
                                    'If you would like to change your role from admin to teacher, please contact your program administrator.';
                            } else {
                                message =
                                    'If you would like to change your role from coach to teacher, please contact your program administrator.';
                            }
                            this.setState({
                                message,
                                header: 'Unable to demote',
                                alertType: 'alert alert-danger',
                            });

                            await this.modal.current.open();
                        } else if (notRole && values.role === 'coach') {
                            if (isTeacher) {
                                message =
                                    'You have changed your role from teacher to coach. Please log in to save this change.';

                                this.setState({
                                    pawn: {
                                        pawnId: data.pawns[0].id,
                                        pawnHash: data.pawns[0].random_hash,
                                    },
                                    header: 'Log-in Required',
                                    message,
                                    alertType: 'alert alert-info',
                                    promote: true,
                                });
                                this.modal.current.open();
                            } else {
                                // isAdmin
                                message =
                                    'You have changed your role to both admin and coach. Please log-in to save this change.';

                                this.setState({
                                    header: 'Log-in Required',
                                    message,
                                    alertType: 'alert alert-info',
                                    makeCoachAdmin: true,
                                    values,
                                });

                                this.modal.current.open();
                            }
                        } else if (notRole && values.role === 'admin') {
                            if (isTeacher) {
                                message =
                                    'You have changed your role from teacher to admin. Please log in to save this change.';

                                this.setState({
                                    pawn: {
                                        pawnId: data.pawns[0].id,
                                        pawnHash: data.pawns[0].random_hash,
                                    },
                                    header: 'Log-in Required',
                                    alertType: 'alert alert-info',
                                    message,
                                    makeAdmin: true,
                                });
                                this.modal.current.open();
                            } else if (isCoach) {
                                message =
                                    'You have changed your role to both admin and coach. Please log-in to save this change.';

                                this.setState({
                                    header: 'Log-in Required',
                                    alertType: 'alert alert-info',
                                    message,
                                    makeCoachAdmin: true,
                                    values,
                                });
                                this.modal.current.open();
                            }
                        } else {
                            // EXISTS && ACTIVATED && SELECTED ROLE -> Landing Page

                            Notification({
                                message: `This account already exists in the system please log-in to continue.`,
                                type: 'success',
                                duration: 3000,
                            });
                        }
                    }
                } else if (!data.user.activated_at) {
                    // EXISTS && NOT ACTIVATED w/ DIFF ROLE - > New Role -> Message -> Activation Page / Email
                    if (notRole) {
                        this.setState({
                            activationData: {
                                promote: values.role === 'coach',
                                demote: values.role === 'teacher',
                                makeAdmin: values.role === 'admin',
                                pawnId: data.pawns[0].id,
                                pawnHash: data.pawns[0].random_hash,
                                token: data.user.activation_token,
                            },
                        });

                        Notification({
                            message: `You have changed your role to ${values.role} please activate to continue.`,
                            type: 'success',
                            duration: 3000,
                        });

                        this.activationModal.current.open();
                    } else {
                        // EXISTS && SELECTED ROLE & NOT ACTIVATED -> Activation Page / Email
                        window.location = Boilerplate.url('/account/activate/' + data.user.activation_token);
                    }
                }
            }
        }
    };

    onActivationSuccess = async () => {
        const {
            activationData: { promote, demote, makeAdmin, pawnId, pawnHash, token },
        } = this.state;
        if (promote) {
            await this.promotePawn({
                pawnId,
                pawnHash,
                token,
            });
            this.activationModal.current.close();
            Notification({
                message: 'Change to Coach Successful!',
                type: 'success',

                duration: 3000,
            });
        } else if (demote) {
            await this.demotePawn({
                pawnId,
                pawnHash,
                token,
            });
            this.activationModal.current.close();
            Notification({
                message: 'Change to Teacher Successful!',
                type: 'success',

                duration: 3000,
            });
        } else if (makeAdmin) {
            await this.makeAdmin({
                pawnId,
                pawnHash,
                token,
            });
            this.activationModal.current.close();
            Notification({
                message: 'Change to Admin Successful!',
                type: 'success',
                duration: 3000,
            });
        }
    };

    validateAdditionalFields = values => {
        let errors = {};

        if (!values.email) {
            errors.email = 'Email is required';
        } else if (!isEmail(values.email.trim())) {
            errors.email = 'Please enter a valid email';
        }

        if (!values.first_name || values.first_name === '') {
            errors.first_name = 'First name is required';
        }
        if (!values.last_name || values.last_name === '') {
            errors.last_name = 'Last name is required';
        }
        if (!values.role) {
            errors.role = 'Role is required.';
        }
        return errors;
    };

    renderSelectBox = () => <Field name="role" component={CccisdSelect} options={roles} label="Role" />;

    render() {
        const pathName = this.props.location.pathname;
        let redirectPath = this.props.match.params.pathName ? pathName.replace('/signUp', '') : '/';

        if (pathName === '/signUp/login') {
            redirectPath = '/';
        }

        const url = Boilerplate.url(redirectPath);

        return (
            <div>
                <div className={style.formBox}>
                    <div className={style.captionBox}>
                        <div className={style.regMsg}>Need to register?</div>
                        <div className={style.logMsg}>Already have an account?</div>
                    </div>
                    <RegisterLoginForm
                        registerProps={{
                            onRegisterSubmit: this.handleSubmit,
                            renderAdditionalFields: this.renderSelectBox,
                            validateAdditionalFields: this.validateAdditionalFields,
                            title: 'Register New User',
                        }}
                        loginProps={{
                            defaultRedirectUrl: url,
                            onSuccess: this.onSuccess,
                            title: 'Log In',
                            buttonText: 'Log In',
                        }}
                    />
                </div>
                <Modal
                    size={this.state.pawn ? 'large' : 'medium'}
                    title={this.state.header}
                    contentStyle={{ width: 'fit-content' }}
                    ref={this.modal}
                    trigger={null}
                    onClose={this.onClose}
                >
                    <div>
                        <h4 className={this.state.alertType}>{this.state.message}</h4>

                        <div style={{ width: '100%', textAlign: 'center', marginTop: '3em' }}>
                            <div className="btn btn-primary" onClick={this.handleClick}>
                                Continue
                            </div>
                        </div>
                    </div>
                </Modal>
                <Modal
                    size={this.state.pawn ? 'large' : 'medium'}
                    title="Activate"
                    contentStyle={{ width: 'fit-content' }}
                    ref={this.activationModal}
                    trigger={null}
                    onClose={this.onClose}
                >
                    {this.state.activationData && (
                        <>
                            <ActivationForm
                                onSuccess={this.onActivationSuccess}
                                activationToken={this.state.activationData.token}
                                alsoLogin
                            />
                        </>
                    )}
                </Modal>
            </div>
        );
    }
}
